@font-face {
    font-family: 'Oswald';
    src: url("./fonts/Oswald/Oswald-Regular.ttf");
}

@font-face {
    font-family: 'Lato';
    src: url("./fonts/Lato/Lato-Regular.ttf");
}

*{
    margin: 0;
    padding: 0;
    font-family: Lato;
    box-sizing: border-box;
}

body>iframe{
    display: none;
}

h3{
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

h2{
    text-align: center;
    font-size: 18px;
}

h1{
    display: flex;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
}

h1 div{
    font-family: 'Oswald';
}


#root{
    width: 100vw;
    height: 100%;
    display: flex;
    padding-top: 30px;
    align-items: center;
    padding-bottom: 50px;
    justify-content: center;
}

.checkout-container{
    width: 50%;
    display: flex;
    min-width: 288px;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    border: 1px solid lightgrey;
    justify-content: space-around;
    box-shadow: 0px 0px 10px 10px rgba(0,0,0,.1);
    background-color: rgba(211, 211, 211, 0.01);
}

.checkout-container > div{
    width: 100%;
    display: flex;
    min-width: 320px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.checkout-container > div:first-child{
    min-height: 400px;
}

.checkout-container > div:last-child{
    min-height: 370px;
    margin-bottom: 41px;
    display: none;
}

.checkout-container img{
    width: 280px;
    min-width: 100px;
}

.label-container{
    width: 80%;
    min-width: 288px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.success,.prefijo{
    display: none;
}

.check-container{
    display: flex;
    width: 100%;
    text-align: start;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-top: 10px;
}

.check-container input{
    width: 25px;
    height: 25px;
    margin-right: 20px;
}

.check-container label{
    font-weight: bolder;
}

.information-container button{
    width: 280px;
    margin-bottom: 30px;
    margin-top: 20px;
}

.cantidad_container{
    width: 90%;
    height: 40px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.cantidad_container label{
    text-align: center;
    width: 100%;
}

#input_cantidad{
    border: 2px solid #da291c;
    height: 40px;
    width: 100%;
    margin-top: 10px;
    text-align: center;
    outline: none;
    border-radius: 10px;
    font-family: 'Oswald';
    font-size: 30px;
}

.datas-container{
    width: 100%;
    margin-bottom: 10px;
}

.datas-container .form-group{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}


.datas-container .form-group input,.datas-container .form-group select{
    border: 1px solid lightgrey;
    height: 35px;
    text-indent: 10px;
    margin-top: 5px;
    border-radius: 5px;
    outline: none;
}

.cantidad-boletos{
    display: flex;
    width: 100%;
    margin-top: 20px;
    flex-direction: column;
    margin-bottom: 10px;
}
.cantidad-boletos input{
    margin-top: 5px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    outline: none;
    text-align: center;
    font-family: 'Oswald';
    font-size: 26px;
}

.is-valid{
    color: green;
    border: 1px solid green !important;
    box-shadow: 0px 0px 3px 3px rgba(0, 128, 0, 0.38);
}

.is-invalid{
    color: #da291c;
    border: 1px solid #da291c !important;
    box-shadow: 0px 0px 3px 3px rgba(218, 41, 28, 0.39);
}

.button-continuar{
    display: flex;
    justify-content: center;
}

.error-message{
    background-color: #da291c;
    margin: 5px 0;
    padding: 10px 10px;
    border-radius: 10px;
    color: white;
    display: none;
    flex-direction: column;
}

.form-group.phone{
    display: none;
}

@media (max-width: 800px){


    .datas-container .form-group label{
        text-align: center;
    }

    .checkout-container{
        width: 90%;
        display: flex;
        flex-wrap: unset;
        flex-direction: column;
        justify-content: space-between;
    }

    .checkout-container > div:first-child{
        width: 95%;
        height: 45%;
        border-bottom: 1px solid lightgrey;
    }


    .checkout-container > div:last-child{
        width: 90%;
        height: 55%;
    }
}